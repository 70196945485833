import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    redirect: '/home',
    component:  () => import('@/views/Index.vue'),
    children: [
      {
        path: '/home',
        name: 'Home',
        component: () => import('@/views/Home/index.vue')
      },
      {
        path: '/store',
        name: 'Store',
        component: () => import('@/views/Store/index.vue')
      },
      {
        path: '/addStore',
        name: 'AddStore',
        component: () => import('@/views/Store/add.vue')
      },
      {
        path: '/updateStore',
        name: 'UpdateStore',
        component: () => import('@/views/Store/update.vue')
      },
      {
        path: '/employee',
        name: 'Employee',
        component: () => import('@/views/Employee/index.vue')
      },
      {
        path: '/addEmployee',
        name: 'AddEmployee',
        component: () => import('@/views/Employee/add.vue')
      },
      {
        path: '/updateEmployee',
        name: 'UpdateEmployee',
        component: () => import('@/views/Employee/update.vue')
      },
      {
        path: '/setStore',
        name: 'SetStore',
        component: () => import('@/views/Employee/setStore.vue')
      },
      {
        path: '/activity/examine',
        name: 'ActivityExamine',
        component: () => import('@/views/Activity/examine.vue')
      },
      {
        path: '/activity',
        name: 'Activity',
        component: () => import('@/views/Activity/index.vue')
      },
      {
        path: '/preactivity/examine',
        name: 'PreactivityExamine',
        component: () => import('@/views/Preactivity/examine.vue')
      },
      {
        path: '/preactivity',
        name: 'Preactivity',
        component: () => import('@/views/Preactivity/index.vue')
      },
      {
        path: '/report/examine',
        name: 'ReportExamine',
        component: () => import('@/views/Report/examine.vue')
      },
      {
        path: '/report',
        name: 'Report',
        component: () => import('@/views/Report/index.vue')
      },
      {
        path: '/userCenter',
        name: 'UserCenter',
        component: () => import('@/views/System/UserCenter.vue')
      },
      {
        path: '/updatePwd',
        name: 'UpdatePwd',
        component: () => import('@/views/System/UpdatePwd.vue')
      },
      {
        path: '/sonAccount',
        name: 'SonAccount',
        component: () => import('@/views/System/SonAccount.vue')
      },
      {
        path: '/addSonAccount',
        name: 'AddSonAccount',
        component: () => import('@/views/System/AddSonAccount.vue')
      },
      {
        path: '/updateSonAccount',
        name: 'UpdateSonAccount',
        component: () => import('@/views/System/UpdateSonAccount.vue')
      },
      {
        path: '/exportActivity',
        name: 'ExportActivity',
        component: () => import('@/views/ExportActivity/index.vue')
      },
      {
        path: '/exportActivity/examine',
        name: 'ExportActivityExamine',
        component: () => import('@/views/ExportActivity/examine.vue')
      },
      // {
      //   path: '/demo',
      //   name: 'Demo',
      //   component: () => import('@/views/demo.vue')
      // }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login/index.vue')
  },
  {
    path: '/print',
    name: 'Print',
    component: () => import('@/views/table-print.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
