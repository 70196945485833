export default {
  'user_login': '/userlogin/sys', // 用户登录
  'user_exit': '/userlogin/exit', // 用户登出
  'store_add': '/store/add', // 网点 - 添加
  'store_update': '/store/update', // 网点 - 修改
  'store_updatebth': '/store/update/bth', // 网点 - 批量修改
  'store_delete': '/store/delete', // 网点 - 删除
  'store_find': '/store/find', // 网点 - 分页查询
  'store_findall': '/store/findall', // 网点 - 查询所有
  'employee_add': '/employee/add', // 员工 - 添加
  'employee_update': '/employee/update', // 员工 - 修改
  'employee_delete': '/employee/delete', /// 员工 - 删除
  'employee_find': '/employee/find', // 员工 - 分页查询
  'employee_findall': '/employee/findall', // 员工 - 查询所有
  'activity_add': '/activity/add', // 活动 - 添加
  'activity_update': '/activity/update', // 活动 - 修改
  'activity_delete': '/activity/delete', // 活动 - 删除
  'activity_find': '/activity/find', // 活动 - 查询
  'activity_findall': '/activity/findall', // 活动 - 查询所有
  'sysuser_add': '/sysuser/add', // 系统用户 - 添加
  'sysuser_update': '/sysuser/update', // 系统用户 - 修改
  'sysuser_delete': '/sysuser/delete', // 系统用户 - 删除
  'sysuser_find': '/sysuser/find', // 系统用户 - 查询
  'sysuser_findall': '/sysuser/findall', // 系统用户 - 查询所有
  'preactivity_add': '/preactivity/add', // 活动上报 - 添加
  'preactivity_update': '/preactivity/update', // 活动上报 - 修改
  'preactivity_delete': '/preactivity/delete', // 活动上报 - 删除
  'preactivity_find': '/preactivity/find', // 活动上报 - 查询
  'preactivity_findall': '/preactivity/findall', // 活动上报 - 查询所有
  'area_add': '/area/add', // 地区 - 添加
  'area_update': '/area/update', // 地区 - 修改
  'area_delete': '/area/delete', // 地区 - 删除
  'area_find': '/area/find', // 地区 - 查询
  'area_findall': '/area/findall', // 地区 - 查询所有
}
